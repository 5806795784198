import styled from "styled-components";
import { 
  Rem, 
  BP,
  Colors,
} from "../../../commons/Theme";
import { StyledFormBlock } from "../../../commons/Form";
import { StyledGridRow } from "../../../commons/Grid";
import { TextInput } from "@3beehivetech/components";

export const StyledWrapperForm = styled(StyledGridRow)`
  margin: 0 auto 0;
`;

export const StyledNewsletterForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${Rem(16)};
  padding: 2rem 4rem;
  border: 2px solid ${Colors.yellow};
  justify-content: center;
  @media (${BP.tablet}) {
    flex-direction: row;
    align-items: center;
    gap: ${Rem(32)};
  }
`;

export const StyledWrapperInputs = styled(StyledFormBlock)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: unset;
  div {
    gap: 0;
    input {
      padding: 15px 20px;
      width: 100%;
      @media (${BP.tablet}) {
        width: 300px;
      }
    }
  }
`;

export const StyledTextInput = styled(TextInput)`
  width: unset;
  height: 3rem;
`;

export const StyledTextArea = styled(TextInput)`
  width: unset;
  height: 8rem;
`;

export const StyledNumberCharacters = styled.p`
  align-self: flex-end;
  font-size: ${Rem(12)};
`;
